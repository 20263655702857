import { useUserStore } from '@/stores';
import nProgress from 'nprogress';
nProgress.configure({ showSpinner: false, parent: "body" });

const responseReturned = (config: any) => {
    nProgress.done();
    return config;
}

const responseError = (error: any) => {
    nProgress.done();
    console.log(error)
    if (error.response.status === 401) {
        useUserStore().logout();
    }
    return Promise.reject(error);
}

export { responseReturned, responseError };