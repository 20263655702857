import { ref } from "vue";
import { defineStore } from "pinia";
import {
  type CalendarCheckDto,
  type CheckDto,
  CheckService,
  type CheckTaskDto,
  type CreateCheckRequest,
  type DeleteApiCheckByIdResponse,
  type GetApiCheckByIdResponse,
  type GetApiCheckCalendarResponse,
  type GetApiCheckListResponse,
  type ListCheckDto,
  type TemperatureRange,
  type UpdateCheckRequest,
} from "@/client";
import { useSettingStore } from "@/stores/setting.store";
import { convertTimeToDateTime } from "@/utils";

export const useCheckStore = defineStore("check", () => {
  const settings = useSettingStore();
  const getTempRangeString = (val: any) => {
    if (val == undefined) return "";
    if (val.min != undefined && 
        val.min != null && 
        val.max != undefined &&
        val.min != null) {
      return `(${val.min}°C - ${val.max}°C)`;
    }
    if (val.min != undefined && val.min != null) return `(> ${val.min}°C)`;
    if (val.max != undefined && val.max != null) return `(< ${val.max}°C)`;
  }

  const inputTypes = ref([
    { text: "Text", value: 0 },
    { text: "Text area", value: 1 },
    { text: "Checkbox (Yes Expected)", value: 2 },
    { text: "Checkbox (No Expected)", value: 16 },
    { text: "Fridge Temperature " + getTempRangeString(settings.settings.fridge), value: 3 },
    { text: "Freezer Temperature " + getTempRangeString(settings.settings.freezer), value: 4 },
    { text: "Cooking Temperature " + getTempRangeString(settings.settings.cooking), value: 6 },
    { text: "Cooling Tempeature " + getTempRangeString(settings.settings.cooling), value: 11 },
    { text: "Hot Probe Temperature " + getTempRangeString(settings.settings.hotProbe), value: 12 },
    { text: "Cold Probe Temperature " + getTempRangeString(settings.settings.coldProbe), value: 13 },
    { text: "Cold Holding Temperature " + getTempRangeString(settings.settings.coldHolding), value: 14 },
    { text: "Hot holding Temperature " + getTempRangeString(settings.settings.hotHolding), value: 5 },
    { text: "Regeneration Temperature " + getTempRangeString(settings.settings.regeneration), value: 15 },
    { text: "Signature", value: 7 },
    { text: "Dropdown", value: 8 },
    { text: "Message", value: 9 },
    { text: "Timestamp", value: 10 },
  ]);
  const checkList = ref([] as ListCheckDto[]);
  const calendar = ref([] as CalendarCheckDto[]);

  async function getCheckCalendar(start: string, end: string) {
    return await CheckService.getApiCheckCalendar({
      from: start,
      to: end,
    })
      .then((response: GetApiCheckCalendarResponse) => {
        calendar.value = response;
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function getCheck(id: string) {
    return await CheckService.getApiCheckById({ id })
      .then((response: GetApiCheckByIdResponse) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function getChecks() {
    return await CheckService.getApiCheckList({ page: 1, pageSize: 1000 })
      .then((response: GetApiCheckListResponse) => {
        if (response.items != undefined) {
          checkList.value = response.items;
        }
        return Promise.resolve();
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function createCheck(check: CheckDto) {
    const insert: CreateCheckRequest = {
      description: check.description,
      name: check.name,
      tasks: check.tasks,
      dueTime: convertTimeToDateTime(check.dueTime)?.toISOString(),
      recurranceRule: check.recurranceRule,
      icon: check.icon,
      recurring: check.recurring,
      canBeFinishedMultipleTimes: true,
      isEnabled: check.isEnabled,

    };
    return await CheckService.putApiCheck({ requestBody: insert })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function updateCheck(check: CheckDto) {
    const update: UpdateCheckRequest = {
      id: check.id,
      description: check.description,
      name: check.name,
      tasks: check.tasks,
      dueTime: convertTimeToDateTime(check.dueTime)?.toISOString(),
      recurranceRule: check.recurranceRule,
      icon: check.icon,
      recurring: check.recurring,
      canBeFinishedMultipleTimes: check.canBeFinishedMultipleTimes,
      isEnabled: check.isEnabled,
    };

    return await CheckService.postApiCheck({ requestBody: update })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function deleteCheck(id: string) {
    return await CheckService.deleteApiCheckById({ id })
      .then((response: DeleteApiCheckByIdResponse) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  return {
    getCheckCalendar,
    getChecks,
    getCheck,
    createCheck,
    updateCheck,
    deleteCheck,
    

    inputTypes,
    checkList,
    calendar,
  };
});
