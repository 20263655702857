import { ref } from 'vue';
import { defineStore } from "pinia";
import { DevicetypeService, type DeviceTypeDto, type CreateDeviceTypeDto, type GetApiDevicetypeListResponse } from '@/client';

export const useDeviceTypeStore = defineStore("devicetype", () => {
    const deviceTypesList = ref([] as DeviceTypeDto[]);

    async function list() {
        return await DevicetypeService.getApiDevicetypeList({page: 1, pageSize: 1000})
        .then((response: GetApiDevicetypeListResponse) => {
            if (response != undefined) {
                deviceTypesList.value = response;
            }
            return Promise.resolve();
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    async function createDeviceType(device: CreateDeviceTypeDto) {
        return await DevicetypeService.putApiDevicetype({requestBody: device})
        .then((response: any) => {
            return Promise.resolve(response);
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    return {
        list,
        createDeviceType,

        deviceTypesList
    };
});