<script setup lang="ts">
    import {onBeforeMount, ref, watch, computed } from "vue";
    import { storeToRefs } from "pinia";
    import {useRoute, useRouter} from "vue-router";
    import { useUserStore, useLocationStore } from "@/stores/";
    import Badge from 'primevue/badge';
    import Dropdown, { type DropdownChangeEvent } from 'primevue/dropdown';
    import { isInRole } from "@/utils";
    import { ROLES } from "@/config";

    const userStore = useUserStore();
    const locationStore = useLocationStore();
    const { locationId } = storeToRefs(userStore);
    const { locations } = storeToRefs(locationStore);
    const router = useRouter();
    const route = useRoute();
    const currentPage = ref(route.path);
    
    var routes = ref(router.getRoutes().filter(r => r.meta.inMenu));

    const changeLocation = (lid: DropdownChangeEvent) => {
        if (userStore.decodedAuth == null) return;
        userStore.decodedAuth!.lid = lid.value;
        userStore.setLocation(lid.value).then(() => {
            window.location.reload();
        });
    }
    const logout = () => {
        userStore.logout();
    }
    onBeforeMount(() => {
        if (userStore.isAuthenticated)
            locationStore.list().then(() => {})  
    });

    watch(route, (to, from) => {
        currentPage.value = to.path;
    });
</script>

<template> 
    <aside v-if="userStore.isAuthenticated" id="mainmenu" class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 bg-white shadow-lg">
        <div class="flex flex-col h-full">
            <div class="text-center block mt-3">
                <div class="flex">
                    <img class="mx-auto h-12 w-auto" src="@/assets/logo.png" alt="CheckMate">
                    <div class="relative p-3 mr-1 relative">
                        <Badge value="2" severity="danger" v-if="false" class="p-overlay-badge fixed right-0 mr-1" />
                        <i class="fa fa-bell" style="font-size: 2rem" />
                    </div>
                </div>
                <div>{{ userStore.tenant }}</div>
            </div>
            <div class="text-center mt-3 px-4" v-if="locations.length > 1">
                <Dropdown
                    :options="locations.map((s:any) => ({text: s.name, value: s.id}))"
                    v-model="locationId"
                    optionLabel="text"
                    optionValue="value"
                    class="w-full" 
                    @change="changeLocation"
                />
            </div>
            <div class="overflow-y-auto mt-4">
                <ul class="list-none px-4 m-0">
                    <li v-for="route in routes" v-bind:key="route.name">
                        <router-link :to="route.path" class="flex items-center cursor-pointer p-3 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-primary hover:text-white dark:hover:bg-surface-700 duration-200 transition-colors" :class="{'bg-primary text-white': currentPage===route.path}">
                            <i :class="route.meta.icon"></i>
                            <span>{{route.name}}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="mt-auto">
                <hr class="mb-3 mx-3 border-t-1  border-surface-200 dark:border-surface-700" />
                <ul class="list-none px-4 m-0">
                    <li v-if="isInRole(ROLES.CHECKMATE)">
                        <router-link  to="/accounts" class="flex items-center cursor-pointer p-3 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" :class="{'bg-primary text-white': currentPage==='/accounts'}">
                            <i class="fa fa-boxes"></i>
                            <span>Accounts</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/locations" class="flex items-center cursor-pointer p-3 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" :class="{'bg-primary text-white': currentPage==='/locations'}">
                            <i class="fa fa-location-dot"></i>
                            <span>Locations</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/users" class="flex items-center cursor-pointer p-3 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" :class="{'bg-primary text-white': currentPage==='/users'}">
                            <i class="fa fa-users"></i>
                            <span>Users</span>
                        </router-link>
                    </li>
                    <li v-if="isInRole(ROLES.ADMIN)">
                        <router-link  to="/settings" class="flex items-center cursor-pointer p-3 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" :class="{'bg-primary text-white': currentPage==='/settings'}">
                            <i class="fa fa-cog"></i>
                            <span>Settings</span>
                        </router-link>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="logout" class="flex items-center cursor-pointer p-3 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors">
                            <i class="fas fa-right-from-bracket"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </aside>
</template>