export const login = {
    title: 'Sign in to your account',
    forgotPassword: 'Forgot Password?',
    notAMember: 'Not a member?',
    register: 'Register now!',
    forgotPasswordTitle: 'Forgot Password',
    forgotPasswordContent: "<p class='pb-3'>Enter your email address and we will send you a link to reset your password</p>",
    forgotPasswordButton: 'Send Reset Link',
    forgotPasswordSuccess: `<b>Password Reset Link Sent!</b><p class='pt-2'>If we found an account with the email address you entered, we've sent password reset instructions to the email address you provided. Please check your email.</p>`,
}

export const registration = {
    title: "Registration",
    mainContent: `<p class='py-3'>Signing up to CheckMate ensures your food safety records are secure and accurate. Using blockchain technology, CheckMate guarantees your records cannot be altered, providing a reliable and tamper-proof system. This reliability is crucial for maintaining compliance with food health and safety standards, helping you avoid legal issues and ensure customer safety.</p>
    <p>Register now to:</p>    
    <ul class="list-none px-10">
        <li class="p-1"><i class='fa fa-check text-primary'></i> Ensure your compliance with FSA guidance.</li>
        <li class="p-1"><i class='fa fa-check text-primary'></i> Have secure and unalterable records.</li>
        <li class="p-1"><i class='fa fa-check text-primary'></i> Speed you your daily compliance checks.</li>
    </ul>`,
    register: "Register",
    registrationComplete: "Registration Complete",
    registrationCompleteContent: `<p class="py-3">Thanks for registering. You will soon receive an email asking for you to confirm your email address.</p>`
}

export const authorize = {
    accountNotFound: `<h1 class="py-5 text-2xl text-center font-bold">Account could not be found</h1>
                        <p class="p-3">Your account could not be found registered on our system.</p>
                        <p class="p-3">Please ensure you clicked on the correct link in your registration email.</p>
                        <p class="p-3">If you have further issues please contact <a class="text-blue-900" href='mailto:help@checkmate.software'>help@checkmate.software</a></p>`,
    accountAlreadyAuthorized: `<h1 class="py-5 text-2xl text-center font-bold">Account is already authorized</h1>
                                <p class="p-3">Your account has already been authorized, try logging in</p>
                                <p class="p-3">If you have further issues please contact <a class="text-blue-900" href='mailto:help@checkmate.software'>help@checkmate.software</a></p>`,
    title: "Account Verified",
    passwordContent: `<p class="py-3">Thanks for verifying your account with CheckMate! While we're getting everything ready for you we have a few we questions to complete your account</p>
                        <p class="py-3">First we need to setup your password so you can access your account</p>`,
    checkSetup: "Setup your checks",
    checkSetupContent: `<p class="py-3">We have a set of template checks that follow the Smarter Business Food Safety Standards which I can automatically set up for you. </p>
                        <p class="py-3">To do that I need some information about your fridges, freezers and ovens. Give each one a name that you can remember and tell us what type is it.</p>
                        <p class="py-3">Note: This can be setup later, if you skip this section we will setup your initial checks with 1 fridge, 1 freezer and 1 oven.</p>`,
    skip: "Skip this step and setup later",
    resetpasswordsuccess: "Password Reset Successful",
}

export const devices = {
    myDevices: "My Devices",
    type: "Type",
    name: "Friendly Name",
    namePlaceholder: "Friendly Name (e.g. Under-counter Fridge)"
}

export const checks = {
    "iconAndName": "Icon & Name"
}

export const issues = {
    "addNote": "Add Note",
}

export const reports = {
    "reports": "Reports",
    "copyright": "Audit records for #location# generated on #date#. Provided by CheckMate",
    "completed": "Completed on ",
    "by": " by ",
    "daterange": "Date Range",
    "day": "Day",
    "daterangereport": "Date Range Check Report",
    "dayreport": "Daily Check Report",
    "download": "Generate & download report",
    "dayreportdownloaded": "Daily Check Report Generated Successfully",
    "daterangereportdownloaded": "Date Range Check Report Downloaded",
    "dayreportdownloadedmessage": "Your report has been generated and downloaded onto your device",
    "daterangereportdownloadedmessage": "Your report has been generated and downloaded onto your device",
}

export const user = {
    "firstname": "First Name",
    "lastname": "Last Name",
    "email": "Email",
    "phone": "Phone",
    "confirmpassword": "Confirm Password",
    "role": "Role",
    "locations": "Locations",
    "available": "Available",
    "selected": "Selected",
    "resetpassword": "Password Reset",
    "btnresetpassword": "Reset Password",
}