export default {
    css: `
      *[data-pd-ripple="true"]{
          overflow: hidden;
          position: relative;
      }
      span[data-p-ink-active="true"]{
          animation: ripple 0.4s linear;
      }
      @keyframes ripple {
          100% {
              opacity: 0;
              transform: scale(2.5);
          }
      }
  
      .progress-spinner-circle {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: 0;
          animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-color 6s ease-in-out infinite;
          stroke-linecap: round;
      }
  
      @keyframes p-progress-spinner-dash{
          0% {
              stroke-dasharray: 1, 200;
              stroke-dashoffset: 0;
          }
  
          50% {
              stroke-dasharray: 89, 200;
              stroke-dashoffset: -35px;
          }
          100% {
              stroke-dasharray: 89, 200;
              stroke-dashoffset: -124px;
          }
      }
      @keyframes p-progress-spinner-color {
          100%, 0% {
              stroke: #ff5757;
          }
          40% {
              stroke: #696cff;
          }
          66% {
              stroke: #1ea97c;
          }
          80%, 90% {
              stroke: #cc8925;
          }
      }
  
      .progressbar-value-animate::after {
          will-change: left, right;
          animation: p-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      }
      .progressbar-value-animate::before {
          will-change: left, right;
          animation: p-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      }
      @keyframes p-progressbar-indeterminate-anim {
          0% {
              left: -35%;
              right: 100%;
          }
          60% {
              left: 100%;
              right: -90%;
          }
          100% {
              left: 100%;
              right: -90%;
          }
      }
  
      .p-fadein {
          animation: p-fadein 250ms linear;
      }
  
      @keyframes p-fadein {
          0% {
              opacity: 0;
          }
          100% {
              opacity: 1;
          }
      }
  `,
  };