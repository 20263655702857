import { ref } from 'vue';
import { defineStore } from "pinia";
import { LocationService, type GetApiLocationResponse, type LocationDto, type PostApiLocationResponse, type PutApiLocationResponse } from '@/client';

export const useLocationStore = defineStore("location", () => {
    const locations = ref([] as LocationDto[]);

    async function list() {
        return await LocationService.getApiLocation()
        .then((response: GetApiLocationResponse) => {
            if (response != undefined) {
                locations.value = response;
            }
            return Promise.resolve();
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    async function create(location: LocationDto) {
        return await LocationService.putApiLocation({requestBody: location})
        .then((response: PutApiLocationResponse) => {
            return Promise.resolve();
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    async function update(location: LocationDto) {
        return await LocationService.postApiLocation({requestBody: location})
        .then((response: PostApiLocationResponse) => {
            return Promise.resolve(response);
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    return {
        list,
        update,
        create,

        locations
    };
});