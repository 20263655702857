import { ref } from "vue";
import { defineStore } from "pinia";
import {
  type DeviceDto,
  DeviceService,
  type GetApiDeviceListResponse,
  OpenAPI,
  type PostApiDeviceCreateResponse,
  type PostApiDeviceResponse,
} from "@/client";
import axios, { type AxiosResponse } from "axios";
import type { CreateDeviceDto } from "@/features/devices/createDeviceDto";

export const useDeviceStore = defineStore("device", () => {
  const deviceList = ref([] as DeviceDto[]);

  async function listDevices() {
    return await DeviceService.getApiDeviceList({ page: 1, pageSize: 1000 })
      .then((response: GetApiDeviceListResponse) => {
        if (response != undefined) {
          deviceList.value = response;
        }
        return Promise.resolve();
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function createDevice(createDeviceDto: CreateDeviceDto) {
    const response = await DeviceService.postApiDeviceCreate({requestBody: {
      name: createDeviceDto.name
    }}).then(async (response: PostApiDeviceCreateResponse) => {
      await listDevices();
      return Promise.resolve();
    })
    .catch((error: any) => {
      return Promise.reject(error);
    });;
    
  }

  async function updateDevice(update: DeviceDto): Promise<boolean> {
    return await DeviceService.postApiDevice({requestBody: {
      id: update.id,
      name: update.name,
      deviceTypeId: update.deviceTypeId,
    }}).then((response: PostApiDeviceResponse) => {
      return Promise.resolve(true);
    })
    .catch((error: any) => {
      return Promise.reject(error);
    });   
  }

  async function deleteDevice(id: string): Promise<AxiosResponse> {
    return await axios.delete(OpenAPI.BASE + "/api/device/delete/" + id);
  }

  return {
    deviceList,
    listDevices,
    createDevice,
    updateDevice,
    deleteDevice,
  };
});
