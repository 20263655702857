import { ref } from 'vue';
import { defineStore } from "pinia";
import { IssueService, type GetApiIssueResponse, type IssueDto, type PostApiIssueAcceptedResponse, type PostApiIssueCommentResponse } from '@/client';

export const useIssueStore = defineStore("issue", () => {
    const issues = ref([] as IssueDto[]);
    const page = ref(0);
    const pageSize = ref(20);
    const totalPages = ref(0);
    const totalCount = ref(0);
    const selected = ref({} as IssueDto);

    async function list() {
        console.log("list", page.value, pageSize.value);
        return await IssueService.getApiIssue({page: page.value, pageSize: pageSize.value})
        .then((response: GetApiIssueResponse) => {
            if (response != undefined) {
                issues.value = response.items!;
                totalPages.value = response.totalPages!;
                totalCount.value = response.totalCount!;
            }
            return Promise.resolve();
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    async function setSeen(id:string) {
        return await IssueService.postApiIssueSeen({requestBody: {id: id}})
        .then((response: PostApiIssueAcceptedResponse) => {
            if (response != undefined) {
                issues.value.filter((issue) => issue.meta!.id == id)[0].meta!.hasBeenSeen = true;
            }
            return Promise.resolve();
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    async function setAccepted(id:string) {
        return await IssueService.postApiIssueAccepted({requestBody: {id: id}})
        .then((response: PostApiIssueAcceptedResponse) => {
            if (response != undefined) {
                issues.value.filter((issue) => issue.meta!.id == id)[0].meta!.hasBeenAccepted = true;
            }
            return Promise.resolve();
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    async function comment(id:number, comment:string) {
        return await IssueService.postApiIssueComment({requestBody: {id: id, comment: comment}})
        .then((response: PostApiIssueCommentResponse) => {
            if (response != undefined) {
                issues.value.filter((issue) => issue.id == id)[0].notes! = response;
            }
            return Promise.resolve();
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    return {
        list,
        setAccepted,
        setSeen,
        comment,
        
        issues,
        page,
        pageSize,
        totalPages,
        totalCount,
        selected
    };
});