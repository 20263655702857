import { ref } from "vue";
import { defineStore } from "pinia";
import {
  type GetApiSettingsResponse,
  type SettingDto,
} from "@/client";
import { SettingsService } from "@/client/";

export const useSettingStore = defineStore("setting", () => {
  const settings = ref({} as SettingDto);
  
  async function get() {
    return await SettingsService.getApiSettings()
      .then(async (x: GetApiSettingsResponse) => {
        settings.value = x;
        return Promise.resolve(x);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  
  async function update(settings: SettingDto) {
    return await SettingsService.postApiSettings({requestBody: settings})
      .then(async (x: GetApiSettingsResponse) => {
        return Promise.resolve(x);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  return {
    get,
    update,

    settings
  };
});
