export const global = {
    emailAddress: "Email Address",
    password: "Password",
    oldpassword: "Old Password",
    newpassword: "New Password",
    firstName: "First Name",
    lastName: "Last Name",
    name: "Name",
    description: "Description",
    company: "Company",
    login: "Login",
    passwordEnter: "Your desired password",
    confirmPassword: "Confirm password",
    continue: "Continue",
    back: "Back",
    schedule: "Schedule",
    tasks: "Tasks",
    label: "Label",
    type: "Type",
    due: "Due",
    multipletimes: "Check can be done multiple times per day",
    recurrance: {
        repeat: "Repeat",
        last: "Last",
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly",
        yearly: "Yearly",
        days: "days",
        weeks: "weeks",
        months: "months",
        years: "years",
        repeatEvery: "Repeat every",
        day: "Day",
        month: "Month",
        onThe: "on the ",
        of: " of ",
        before: "Before",
        monthnames: {
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December"
        },
        weekdays: {
            sunday: "Su",
            monday: "Mo",
            tuesday: "Tu",
            wednesday: "We",
            thursday: "Th",
            friday: "Fr",
            saturday: "Sa"
        }
    }
}