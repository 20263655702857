export const errors = {
    required: "{field} is required",
    valid: "A valid {field} is required",
    exists: "{field} already has an account",
    min: "{field} must be at least {num} characters",
    matchPasswords: "Passwords must match",
    "Invalid user": "Email or password did not match",
    Unauthorized: "Your account has been locked after too many failed login attempts. Please  try again in 5 minutes.",
    "User account deactivated": "Your account is not active. Please make sure you have verified your email address before logging in.",
    resetexpired: "Your password reset link has expired. Please request a new one.",
    passwordcomplexity: "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    oldpassword: "Your new password must be different from your old password",
}