<script lang="ts" setup>
import { useRoute } from "vue-router";
import Menu from "@/components/layout/menu.vue";
import Toast from "primevue/toast";
import ConfirmPopup from "primevue/confirmpopup";

const route = useRoute();
const showMenu = () => {
  const menu = document.querySelector("#mainmenu") as HTMLElement;
  menu.classList.toggle("translate-x-0");
};
</script>

<template>
  <main v-if="route.meta.hideUI">
    <RouterView />
    <Toast />
  </main>
  <main v-else>
    <div class="sm:hidden text-right">
      <button
        class="inline-flex items-end p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        type="button"
        @click="showMenu"
      >
        <span class="sr-only">Open sidebar</span>
        <svg
          aria-hidden="true"
          class="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            fill-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
    <div class="flex sm:flex-col">
      <RouterView v-slot="{ Component }">
        <Menu></Menu>
        <div class="sm:ml-64 p-5">
          <component :is="Component" />
        </div>
      </RouterView>
    </div>
    <Toast />
    <ConfirmPopup />
  </main>
</template>
