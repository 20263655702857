import { defineStore } from "pinia";
import { 
  TenantService, 
  UserService, 
  type CheckCreationDetailsDeviceDto, 
  type GetApiTenantExistsByCompanyResponse, 
  type GetApiUserExistsByEmailResponse, 
  type PostApiTenantActivateResponse, 
  type PostApiTenantCreateDefaultsByIdResponse, 
  type PutApiTenantAdminCreateResponse,
  type GetApiTenantResponse,
  type PutApiTenantResponse} from "@/client/";


export const useTenantStore = defineStore("tenant", () => {
  
  async function registerTenant(company:string, firstName: string, lastName: string, email:string) : Promise<PutApiTenantResponse> {
    try {
      return await TenantService.putApiTenant({requestBody: {company: company, firstName: firstName, lastName: lastName, email: email}})
        .then((response: PutApiTenantResponse) => {
          return Promise.resolve(response);
        })
        .catch((error:any) => {
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function activateTenant(id:string) {
    return await TenantService.postApiTenantActivate({requestBody: {id: id}})
      .then((response: PostApiTenantActivateResponse) => {
        return Promise.resolve(response);
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }

  async function checkTenantExists(name:string) {
    return await TenantService.getApiTenantExistsByCompany({company: name})
      .then((response: GetApiTenantExistsByCompanyResponse) => {
        return Promise.resolve(response);
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }

  async function checkEmailExists(email:string) {
    return await UserService.getApiUserExistsByEmail({email: email})
      .then((response: GetApiUserExistsByEmailResponse) => {
        return Promise.resolve(response);
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }

  async function createDefaultChecks(deviceList: CheckCreationDetailsDeviceDto[], id: string) {
    return await TenantService.postApiTenantCreateDefaultsById({id: id, requestBody: {devices: deviceList}})
      .then((response: PostApiTenantCreateDefaultsByIdResponse) => {
        return Promise.resolve(response);
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }


  async function getAll() {
    return await TenantService.getApiTenant()
      .then((response: GetApiTenantResponse) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function quickCreateNew(company:string, firstName:string, lastName:string, email:string, password: string) {
    return await TenantService.putApiTenantAdminCreate({requestBody: {company: company, firstName: firstName, lastName: lastName, email: email, password}})
      .then((response: PutApiTenantAdminCreateResponse) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }


  return {
    registerTenant,
    activateTenant,
    checkTenantExists,
    checkEmailExists,
    createDefaultChecks,
    getAll,
    quickCreateNew,
  };
});
